
// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from "gatsby-image";

// components
import Layout from '../components/layout';
import { Section, Container } from '../components/styles/general';
import { ServicePreviewTemplate } from '../templates/services-page';
import ContactForm from "../components/contactForm";

export default class IndexPage extends React.Component {
  constructor(props){
    super(props);
    this.state={
      galleryImageIdx: undefined
    }
    this.wrapperRef = React.createRef()
  }

  openImage = (id) => {
    console.log(id)
    this.setState({ galleryImageIdx: id });
  }

  closeGallery = (ev) => {
    ev.stopPropagation();
    this.setState({ galleryImageIdx: undefined });
  }

  /**
   * - moves back
   * + moves forward
   * @param {number} direction 
   */
  navigateGallery(direction){
    return (ev)=>{
      ev.stopPropagation();
      const gallery = this.props.data.allFile.edges
      const newGalleryIdx = this.state.galleryImageIdx + direction
      console.log(newGalleryIdx)
      if( newGalleryIdx > gallery.length - 1){
        this.setState({ galleryImageIdx: 0});
        return
      } else if( newGalleryIdx < 0){
        this.setState({ galleryImageIdx: gallery.length - 1});
        return
      } else {
        this.setState({ galleryImageIdx: newGalleryIdx});
      }
    }
  }


  render() {
    const { data } = this.props;
    const { galleryImageIdx } = this.state;
    const { edges: services } = data.allMarkdownRemark;
    const { edges: gallery} = data.allFile;
    return (
      <>
        <Layout>
          <Section style={{ padding: 0, maxHeight: '80vh', height: '700px', width: '100%', overflow: 'hidden', position: 'relative' }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.8)',
                position: 'absolute',
                zIndex: '1'
              }}
            >
              <div style={{ fontSize: '80px' }}> Ocean West Tree Service</div>
            </div>
            <video autoPlay loop muted style={{ width: '100%', position: 'absolute', top: 0 }}>
              <source src="/img/Blurry_Trees_Seamless_Loop_01_Videvo.mov" />
            </video>
          </Section>
          <Section id="services">
            <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              {services.map(({ node: project }) => (
                <div style={{ display: 'flex', maxWidth: '300px', flex: 1 }}>
                  <ServicePreviewTemplate
                    key={project.id}
                    service={project.frontmatter.service}
                    tags={project.frontmatter.tags}
                    description={project.excerpt}
                    logoUrl={project.frontmatter.icon}
                  />
                </div>
              ))}
            </Container>
          </Section>
          <Section background={'rgba(193,171,16,0.22)'} id="certifications">
            <Container>
              <h3>Certifications</h3>
              images of cets here
            </Container>
          </Section>
          <Section id="contact">
            <Container>
              <h3 style={{ marginBottom: '16px' }}>Contact Us</h3>
              <ContactForm />
            </Container>
          </Section>
          <Section background={'rgba(0,0,0,0.1)'} id="about">
            {/* <Container> */}
              <div className="gallery">
                {gallery.map((image, idx)=>
                  <div onClick={()=> this.openImage(idx)} className="img-container">
                    <div className="overlay"/>
                    <Img fluid={image.node.childImageSharp.fluid} className="img"/>
                  </div>
                )}
              </div>
            {/* </Container> */}
          </Section>
        </Layout>
        {
          galleryImageIdx !== undefined && <div className="gallery-preview" ref={this.wrapperRef} onClick={this.closeGallery}>
            <span className="close icon" onClick={this.closeGallery}> 
              <i className="fas fa-times" />
            </span> 
            <span className="prev icon" onClick={this.navigateGallery(-1)}> 
              <i className="fas fa-chevron-left" />
            </span> 
            <Img fluid={gallery[galleryImageIdx].node.childImageSharp.fluid} className="fullSizeImage" />  
            <span className="next icon"  onClick={this.navigateGallery(1)}>
              <i className="fas fa-chevron-right" />
            </span> 
          </div>
        }
      </>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
        query IndexQuery {
          allFile(filter: { sourceInstanceName: { eq: "postImages" } }) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
           allMarkdownRemark(
             sort: { order: DESC, fields: [frontmatter___title] }
             filter: { frontmatter: { templateKey: { eq: "services-page" } } }
           ) {
             edges {
               node {
                 excerpt(pruneLength: 400)
                 id
                 frontmatter {
                   templateKey
                   service
                   icon
                   tags
                 }
               }
             }
           }
         }
       `;


// libraries
import React, { useState} from 'react';
import { Formik, Form } from 'Formik';
import * as yup from 'Yup';
import styled from 'styled-components'

// MUI
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const emailSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    message: yup.string().required()
})

const FormComponent = styled(Form)`
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    & > * {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
    }
`

const ContactForm = () => {
    const [disabled, setDisabled] = useState(false)
    const sendEmail = async (values, actions) => {
        try {
            const { name, email, message } = values
            setDisabled(true)
            const res = await fetch({
                method: "post",
                url: "/api/sendEmail",
                body: JSON.parse({ 
                    cc: email,
                    subject: `Website Email from ${name}`,
                    data: message,
                })
            })
            console.log(values)
        } catch(err){
            console.log(err)
        } finally{
            setDisabled(true)
        }
    }
    
    return (
        <Formik
            initialValues={{ 
                name: '',
                email: '',
                message: '',
            }}
            validationSchema={emailSchema}
            onSubmit={(values, actions) => {
                sendEmail(values, actions)
            }}>
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched}) => (
                <FormComponent onSubmit={handleSubmit}>
                    <TextField 
                        id="name" 
                        name='name' 
                        label="name" 
                        disabled={disabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                    />
                    <TextField 
                        id="email" 
                        name='email' 
                        label="email" 
                        disabled={disabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && errors.email}
                        helperText={touched.email && errors.email}
                        variant="outlined"
                        
                    />
                    <TextField 
                        id="message" 
                        name='message' 
                        label="message" 
                        disabled={disabled}
                        multiline
                        rows={4}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.message}
                        error={touched.message && errors.message}
                        helperText={touched.message && errors.message}
                        variant="outlined"
                        />
                    <Button variant="outlined" disabled={disabled} type="submit"> Send</Button>
                </FormComponent >
            )}
        </Formik>
    )
}

export default ContactForm